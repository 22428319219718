exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-internal-about-js": () => import("./../../../src/pagesInternal/about.js" /* webpackChunkName: "component---src-pages-internal-about-js" */),
  "component---src-pages-internal-contacts-js": () => import("./../../../src/pagesInternal/contacts.js" /* webpackChunkName: "component---src-pages-internal-contacts-js" */),
  "component---src-pages-internal-cookies-js": () => import("./../../../src/pagesInternal/cookies.js" /* webpackChunkName: "component---src-pages-internal-cookies-js" */),
  "component---src-pages-internal-partnerships-js": () => import("./../../../src/pagesInternal/partnerships.js" /* webpackChunkName: "component---src-pages-internal-partnerships-js" */),
  "component---src-pages-internal-prices-js": () => import("./../../../src/pagesInternal/prices.js" /* webpackChunkName: "component---src-pages-internal-prices-js" */),
  "component---src-pages-internal-privacy-js": () => import("./../../../src/pagesInternal/privacy.js" /* webpackChunkName: "component---src-pages-internal-privacy-js" */),
  "component---src-pages-internal-rooms-js": () => import("./../../../src/pagesInternal/rooms.js" /* webpackChunkName: "component---src-pages-internal-rooms-js" */),
  "component---src-pages-internal-terms-js": () => import("./../../../src/pagesInternal/terms.js" /* webpackChunkName: "component---src-pages-internal-terms-js" */),
  "component---src-pages-internal-testemunials-js": () => import("./../../../src/pagesInternal/testemunials.js" /* webpackChunkName: "component---src-pages-internal-testemunials-js" */),
  "component---src-pages-marketing-js": () => import("./../../../src/pages/marketing.js" /* webpackChunkName: "component---src-pages-marketing-js" */)
}

